var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("company-select", {
        staticClass: "mb-3",
        on: { input: _vm.companySelected },
      }),
      _c("div", { staticClass: "row-or", attrs: { "data-cy": "or" } }, [
        _c("div", [_vm._v("\n      or\n    ")]),
      ]),
      _c(
        "b-col",
        { staticClass: "text-center" },
        [
          _c(
            "b-button",
            {
              attrs: {
                "data-cy": "addBtn",
                to: "/hire-us/add-company",
                variant: "primary",
              },
            },
            [_vm._v("\n      Add a New Company to Your Account\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }