<template>
  <b-container>
    <company-select class="mb-3" @input="companySelected" />
    <div data-cy="or" class="row-or">
      <div>
        or
      </div>
    </div>
    <b-col class="text-center">
      <b-button data-cy="addBtn" to="/hire-us/add-company" variant="primary">
        Add a New Company to Your Account
      </b-button>
    </b-col>
  </b-container>
</template>

<script>
  import CompanySelect from '@/components/CompanySelect'
  import router from '@/routes'
  import { mapActions } from 'vuex'
  import { tagSession } from '@/common/modules/inspectlet'

  export default {
    name: 'ChooseCompany',
    components: {
      CompanySelect,
    },
    async mounted() {
      await this.resetState()
    },
    methods: {
      ...mapActions('checkout', ['resetState']),
      ...mapActions('companies', ['setCurrentCompany']),
      async companySelected(companyId) {
        tagSession({ hireUs: 'hireUs', companyId: companyId })

        await this.setCurrentCompany({ id: companyId, force: true })
        // Todo do we need to provide params anymore, now that we're settingCurrentCompany above?
        await router.push({ name: 'choose-category', params: { companyId } })
      },
    },
  }
</script>
<style lang="scss">
  .row-or {
    margin: 2rem auto;
    width: 35%;
    height: 1px;
    background-color: $ct-ui-font-color-secondary;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div {
      color: $ct-ui-font-color-secondary;
      padding: 20px;
      background: white;
      text-align: center;
    }
  }
</style>
